<template>
    <div class="contact">
        <h1>{{ $t('contact.headingFirst') }}</h1>

        <email-us />
    </div>
</template>

<script>
import EmailUs from '@/components/EmailUs'

export default {
    name: 'Contact',
    components: {
        EmailUs,
    },
}
</script>

<style lang="scss">
.contact {
    margin: 0 -18px;
    padding: 60px 18px 0;
    background-color: #616062;

    h1 {
        padding-top: 12px;
        color: white;
        font-size: 30px;
        font-weight: 500;

        @media (min-width: 1024px) {
            margin-bottom: 36px;
            font-size: 40px;
        }
    }

    @media (min-width: 768px) {
        padding: 66px 0 0;
    }

    @media (min-width: 1024px) {
        padding: 78px 48px 48px;
    }

    @media (min-width: 1300px) {
        padding: 132px 66px 66px;
    }

    .email-us {
        padding-top: 12px;
    }
}
</style>
